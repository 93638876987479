import { $domain } from '@/plugins/domain';

export const isPurchaseEnabled = (): boolean => {
  if ($domain.isMHMMypage) return false;
  if ($domain.isSHKommentar) return false;
  if ($domain.isSHKommentarLibrary) return false;
  if ($domain.isKenpon) return false;

  return true;
};
