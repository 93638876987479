/**
 * ShopifyのMultipass認証先URLを返す
 * @param returnPath 認証後にリダイレクトする Store の path
 */
export const getShopifyMultipassAuthUrl = (returnPath: string): string => {
  return `/auth/api/store/multipass-login?returnPath=${encodeURIComponent(returnPath)}`;
};

/**
 * Shopifyでの書籍詳細URLを返す
 * @param docNaturalId doc.natural_id
 */
export const shopifyDocDetailsUrl = (docNaturalId: string): string => {
  const baseUrl = new URL(process.env.SHOPIFY_STORE_ORIGIN!);
  return new URL(shopifyDocDetailsPath(docNaturalId), baseUrl).href;
};

export const shopifyDocDetailsPath = (docNaturalId: string): string => {
  return `/p/${docNaturalId}`;
};
