<template>
  <layout-normal>
    <template #main>
      <section class="new-release-headline">
        <h1 class="heading">購入済み書籍</h1>
      </section>
      <div v-if="!results.length">
        <p class="noEntry">
          購入済みコンテンツがありません。
          <a :href="getStoreTopPageUrl()" target="_blank">Legalscape Store</a>
          にてコンテンツを購入すると、こちらにコンテンツが表示されるようになります。 <br />
        </p>
      </div>
      <div>
        <section
          v-for="document in results"
          :key="document.id"
          :ref="`searchResultEntry-${document.id}`"
          class="search-result-entry my-5"
          style="margin-top: 32px"
        >
          <v-card width="100%" style="padding: 1px">
            <document-list-item
              :disable-document-link="disableDocumentLink"
              :document="document"
              :target="'_blank'"
              :disabled="true"
              variant="small"
              class="ma-4"
            />
          </v-card>
        </section>
      </div>
    </template>
  </layout-normal>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';
import LayoutNormal from '@/components/base/layout-normal.vue';
import DocumentListItem from '@/components/document-list-item.vue';
import { isPurchaseEnabled } from '@/utils/featureToggles';
import { DocRecord } from 'wklr-backend-sdk/models';
import { getShopifyMultipassAuthUrl } from '@/utils/shopifyUtils';

const PER_PAGE = 20;

type AsyncData = Pick<Purchased, 'results'>;

@Component({
  components: { DocumentListItem, LayoutNormal },
})
export default class Purchased extends Vue {
  /** 結果 */
  results: DocRecord[] = [];

  head(): MetaInfo {
    return { title: '新着図書' };
  }

  get disableDocumentLink(): boolean {
    return this.$domain.isMHMMypage;
  }

  getStoreTopPageUrl(): string {
    const storeOrigin = process.env.SHOPIFY_STORE_ORIGIN;
    if (!storeOrigin) {
      return '';
    }
    return getShopifyMultipassAuthUrl(storeOrigin);
  }

  async asyncData({ app: { $repositories }, error }: Context): Promise<AsyncData | undefined> {
    if (!isPurchaseEnabled()) {
      error({ statusCode: 403, message: 'このページは閲覧できません' });
    } else {
      return {
        results: await $repositories.users.purchasedDocuments(PER_PAGE),
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.new-release-headline {
  padding: 24px 0px 12px;

  > .heading {
    padding: 15px 0;
    font-size: 28px;
    color: #000000de;
  }
}
</style>
